<template lang="pug">
.container_meet(:style="{ height: 'calc(100vh - 74px)', width: meetType === 'agent' ? '500px' : '100%', margin: meetType === 'agent' ? '0px auto' : '' }")
  div(:style="attendees.length < 2 ? 'margin: 20px 0 0 0;' : ''").col-12.p-0.header.p-1.position-relative
    div(class="version-position") {{ version }}
    transition(name="fade")
      .videoStopSuggestionMessage.text-danger-dk(v-if="videoStopSuggestion") Por problemas de red, hemos desactivado tu cámara. 
    //- div(v-if="meetType === 'agent'")
    //-   h5(
    //-     v-if="Array.isArray(attendees) && attendees.length < 2"
    //-     style="margin-top: 9vh; text-align: center; color: #212529;"
    //-   ) Esperando Cliente...
    div(v-if="meetType != 'agent'")
      //- h5(
      //-   v-if="turn.meet && turn.meet.assistants.length < 2"
      //-   style="margin-top: 9vh; text-align: center; color: #212529;"
      //- ) Esperando Asesor...

      .main_box(v-if="attendees.length < 2")
        .col-12(style="z-index: 1;" )
          h4.mt-4.text-middle.color-colmedica-gray Tu turno asignado es:
          //- h4.text-middle.color-colmedica-gray número:
          h3.text-middle.color-colmedica-light.font-weight-bold {{ assignTurn }}
          h5.mt-5.text-middle.color-colmedica-gray En breve te conectaremos...

          //- p.color-colmedica En este momento presentamos congestión en nuestro canal, si ya enviaste foto de la orden médica y no vas a acceder inmediatamente al servicio, puedes cancelar la solicitud y te daremos respuesta al correo y te contactaremos si requerimos información adicional..

  .d-flex.justify-content-center.ContainerFlexMedia
    .col-12.p-0.videozone(v-show="meetType == 'agent' || attendees.length >= 2")
      .boxAttendee
        h4.text-center.AssignmentTextAttendee.mb-0.mt-1  Turno en atención
        h3.text-center.font-weight-bold.ImportantAsignment.mb-1 {{ assignTurn }}
      VideoCall(
        :audioId="audioId",
        :videosIds="videosIds"
        @CamChange="selectedDeviceInput('VideoInput')"
      )
      .meetHelper(v-show="meetType == 'agent' || attendees.length >= 2")
        .d-flex.justify-content-center.align-items-center
          div(:class="attendees.length >= 2 ? 'green-circle' : 'red-circle'")
          h5.px-3.pt-0.m-0: span.d-block Asesor:
        h5.px-3.pt-0.m-0: strong {{ helperAgent }}
      .bottomzone
        .container-fluid
          .row
            .col.text-center(
              style="height: 50px;"
              v-if="meetType === 'agent'"
              @click="toogleSharingEnabled"
              :class="{ 'desktopSharingArea isShared': isSharedVideo, 'desktopSharingArea isNotShared': !isSharedVideo }"
            )
              span(:style="{ color: isSharedVideo ? '#20a464' : '#FB0101' }")
                MonitorShare(class="size-buttons" size="100%")
            .col.text-center.listStatusDialogShower(
              style="height: 50px;"
              @click="$bvModal.show('List-status')" v-if="debugButton"
            )
              span(style="color: #FB0101")
                ListStatus(class="size-buttons" size="100%")
            .col.text-center(
              :class="{ 'speakerArea isShowed': speakerEnabled, 'speakerArea isHiden span': !speakerEnabled }"
              style="height: 30px;"
              v-if="false"
            ).p-0.mx-3
              span(style="color: #FB0101")
                img(:src="speakerEnabled ? VolumeHigh : VolumeHighOff" size="100%").size-buttons
            .col.text-center(
              :class="{ 'micArea isShowed': audioEnabled, 'micArea isHiden span': !audioEnabled }"
            ).p-0.mx-3
              //- .tap#microphone(v-show="isModeTutorial")
              span(style="color: #FB0101" @click="toogleAudioEnabled()").position-relative
                img(:src="audioEnabled ? Mic : MicOff" size="100%").size-buttons.size-buttonMic
                p.text-label Micrófono
            .col.text-center(
              :class="{ 'camArea isShowed': camEnabled, 'camArea isHiden span': !camEnabled }"
            ).p-0.mx-3
              //- .tap#video_camera(v-show="isModeTutorial")
              span(style="color: #FB0101" @click="startCamFromBasis").position-relative
                img(:src="camEnabled ? VideoCam : VideoCamOff" size="100%").size-buttons
                p.text-label Cámara
            .col.text-center.cancelMeetDialog
              //- .tap#finish_video_call(v-show="isModeTutorial")
              span(style="color: #FB0101" @click="showEndMeeting = true")
                img(:src="PhoneOff" size="100%").size-buttons
                p.text-label Finalizar
    .chatContainerFlex
      .col-12.p-0.chatzone(v-show="meetType == 'agent' || attendees.length >= 2")
        .chatWrapper(v-show="allowChat")
          .containerMsg
            .position-relative.fullChat
              .messagesZone.p-2
                .containerInputMessages
                  .input-group.m-2.w-100(v-show="allowChat")
                    input(
                      type="file"
                      accept="*/*"
                      style="display: none"
                      ref="file"
                      @change="fileHandlerGeneral"
                    )
                    .input-group-append.position-relative
                      //- .tap#add_files(v-show="isModeTutorial")
                      span.input-group-text.attach_btn.py-0.px-2.d-flex.justify-content-center.align-items-center.attachFileToChat(
                        @click="simulateAttach"
                        :disabled="loadingFile"
                        :style="{ borderRadius: '5px 0px 0px 5px' }"
                      )
                        Attach(size="25" class="d-block rotatedk")
                      //- span.input-group-text.attach_btn.py-0.px-2.d-flex.justify-content-center.align-items-center.attachImageToChat(
                      //-   @click="simulateImage"
                      //-   :disabled="loadingFile"
                      //- )
                      //-   Camera(size="25" class="d-block")
                    .position-relative.inputContainer
                      //- .tap#write_message(v-show="isModeTutorial")
                      input.form-control.type_msg(placeholder="Ingrese su mensaje..." v-model="message" @change="handleChangeMessage" @keyup.enter="messageHandler")
                    .input-group-append.position-relative(@click="messageHandler")
                      //- .tap#send_message(v-show="isModeTutorial")
                      span.input-group-text.send_btn.py-0.px-2.d-flex.justify-content-center.align-items-center
                        img(src="@/assets/older_adults/sendIcon.svg" alt="sendIcon" v-if="!showSendIcon").sendIcon
                        img(src="@/assets/older_adults/sendIcon.svg" alt="sendIcon" v-else).sendIcon
                .messages#full_messages
                  .d-flex.mt-3.messageCard(
                    v-for="(msg, i) in messages" :key="`message_${i}`"
                    @click="download(i)"
                    :id="`message_${i}`"
                    :class="{ 'justify-content-start': !msg.isSelf, 'justify-content-end': msg.isSelf, 'uniqueMessage': msg.text.includes('DKMessageUnique1112233445566778899101000'), 'marginNegative': msg.text.includes('DKLocoarsaTUUIO123456') }"
                    v-if="msg.text != '33Silver49Finalizado' && !msg.text.includes('hidden-attach:') "
                  )
                    .msg_cotainer(style="max-width: 80%; min-width: 162px;")
                      div(:class="{ 'triangulo': msg.isSelf, 'triangulo_noself': !msg.isSelf }" v-if="!msg.text.includes('DKMessageUnique1112233445566778899101000')")
                      .msg_owner.justify-content-start(:class="{ 'msg_owner_client': msg.isSelf, 'msg_owner_agent': !msg.isSelf }")
                        p.pharragraph_message(style="font-size: 12px") {{ nameParticipant(msg.text) }}
                        //- span(style ="margin-left: 5px;" :class="{ 'msg_time': !msg.isSelf, 'msg_time_send': msg.isSelf }") {{ timeFormat(msg.timestamp) }}
                      div(:class="{'loading_msg-dk': msg.text.includes('loading123456789987456123'), 'msg_unique_text': msg.text.includes('DKMessageUnique1112233445566778899101000'), 'msg_text': !msg.text.includes('DKMessageUnique1112233445566778899101000')}" :style="{ backgroundColor: msg.isSelf ? 'rgb(33, 38, 36)' : 'rgb(84, 103, 143)', color: 'white', paddingLeft: '10px' }")
                        p(style="margin: 0;" v-html="msg.text.split('name:')[0]")
                        p(v-if="!msg.text.includes('DKMessageUnique1112233445566778899101000')" style ="margin: 0; margin-left: 5px;" :class="{ 'msg_time': !msg.isSelf, 'msg_time_send': msg.isSelf }").msg_timestamp {{ timeFormat(msg.timestamp) }}
                  .d-flex.justify-content-end.mt-3.mr-3.messageCard(
                    v-if="loadingFile"
                  )
                    .msg_cotainer(style="max-width: 80%; min-width: 162px;")
                      div.triangulo.disabled-triangle
                      div.msg_text(style="background-color: rgb(33 38 36 / 60%); color: #d8dee2;")
                        div.position-relative.DKLocoarsaTUUIO123456
                          div(style="display: flex; justify-content: space-between;")
                            //- p Enviando archivo ...
                            //- img(src="./loading-buffering.gif" alt="loading" style="max-width: 19px; width: 100%; height: 19px;")
                            progress-bar(size="medium" :val="valueProgress" style="width: 100%; height: 30px;" :text="`Enviando archivo ... ${valueProgress}%`" :text-fg-color="'white'")
                        a.folderIcon-DK.text-truncate.pt-0.pb-0(href="#" target="_blank" @click="(e) => e.preventDefault()" style="color: #d8dee2; height: 30px;")
                          svg(style="width:24px;height:24px" viewBox="0 0 24 24")
                            path(fill="white" d="M20,6A2,2 0 0,1 22,8V18A2,2 0 0,1 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10L12,6H20M19.25,13H16V9H14V13H10.75L15,17.25")
                          span(style="margin: 0; height: 30px;").fileChatOK {{ fileNameLoading }}
                          span .{{ fileExtension }}
                b-button.arrowBtnDown(variant="bluecolmedica" v-if="showBtnScroll" @click="scrollHandler").showBtnScroll
                  span.bg-btn
                  ArrowDown(size="25" color="#6c757d")
                  .redAlert
                b-button.arrowBtnDown(variant="bluecolmedica" v-if="!showBtnScroll && showVisibleTape" @click="scrollHandler").showBtnScroll
                  span.bg-btn
                  ArrowDown(size="25" color="#6c757d")
                .typing(v-if="typing")
                  .triangulo-chat
                  .lds-ellipsis
                    .into-ellipsis
                    .into-ellipsis
                    .into-ellipsis
                    .into-ellipsis
                .typing-2(v-if="typing")
                  .triangulo_noself-chat
                  .lds-ellipsis
                    .into-ellipsis
                    .into-ellipsis
                    .into-ellipsis
                    .into-ellipsis
      b-modal(id="Agent-not-connect" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="p-2 position-relative w-auto h-auto")
          div(class="d-block text-center")
            p(class="my-4 font-weight-bold text-danger-dk") Lo sentimos
            p(class="my-4 text-danger-dk") No fue posible conectarte con {{ meetType != 'agent' ? 'un' : 'cliente' }}
            p(class="my-4 text-danger-dk") {{ meetType != 'agent' ? 'cliente' : 'asesor' }} en este momento
            p(class="my-4") ¿Qué deseas hacer?
          div(class="position-relative")
            b-button(@click="$router.push({ path: '/turno' })" variant="outline-danger" class="btn-block ") Reintentar conectar
            b-button(@click="end" variant="danger" class="btn-block") Cancelar solicitud

      Modal(
        v-if="showEndMeeting"
        :hideHeader="true"
      )
        template(#main)
          .my-0.text-center
            p.m-0.font-weight-bold.text-danger-dk Estás a punto de finalizar la
            p.m-0.font-weight-bold.text-danger-dk videollamada
          div(class="p-2 position-relative w-auto h-auto")
          div(class="d-block text-center" )
            //- p(class="m-0 text-danger") Esto cancelará el proceso de atención
            //- p(class="m-0 text-danger") con el {{ meetType != 'agent' ? 'asesor Colmédica' : 'cliente' }}
            p.mt-2.pb-3.color-black ¿Qué deseas hacer?
          div(class="position-relative")
            b-button(@click="showEndMeeting = false" variant="bluecolmedica" class="btn-block") Volver a la videollamada
            b-button(@click="end(true)" variant="bluecolmedica" class="btn-block cancelMeetConfirmation").dangerButton Finalizar la videollamada
      b-modal(id="Input-selector" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="p-2 position-relative w-auto h-auto")
          div(class="d-block text-center")
            div(
              class="toggle my-2"
              v-show="deviceSelector != 'VideoInput' "
            )
              img(id="deviceOff" class="icon-toogle" :src="deviceSelector == 'AudioInput' ? MicOff : VolumeHighOff")
              toggle-button(
                @change="disableDevice(true)"
                :value="toogleDevice"
                :width="140"
                :height="30"
                color="#82C7EB"
                :labels="false"
                class="my-0 mx-2"
              )
              img(id="deviceOn"  class="icon-toogle" :src="deviceSelector == 'AudioInput' ? Mic : VolumeHigh")
            template(v-if="toogleDevice" v-for="device in selectedDeviceAllowed")
              b-button(
                v-if="device && device.deviceId"
                :key="device.deviceId"
                @click="changeDeviceSelected(device)"
                block
                :variant="toogleDevice && deviceCurrent && deviceCurrent.deviceId == device.deviceId ? 'bluecolmedica' : 'outline-bluecolmedica'"
                class="my-2 fontSizeButton"
              ) {{ device.label }}

      b-modal(id="List-status" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer :static="true")
        ModalListStatus(class="p-2 position-relative w-auto h-auto")

      b-modal(id="Allowed-video" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer)
        h5 Por favor activa tu cámara.
        .buttons.mt-4
          b-button(@click="startCameraHandler" class="camDialogActivate") Activar
          b-button.ml-2.camDialogDeactivate(@click="toogleCamModal") Desactivar
      b-modal(
        id="invalid-mime"
        footerClass='p-2 border-top-0'
        headerClass='p-2 border-bottom-0'
        centered=true
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
      )
        h6 Soportamos archivos que pesen menos de 10Mb y sean de tipo imagen (jpeg, tiff, png) y pdf.
        .buttons.mt-4.mimeErrorCloser
          b-button.ml-2(@click="toogleInvalidModal") Cerrar

      b-modal(
        id="invalid-size"
        footerClass='p-2 border-top-0'
        headerClass='p-2 border-bottom-0'
        centered=true
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
      )
        h6 Soportamos archivos que pesen menos de 10Mb y sean de tipo imagen (jpeg, tiff, png) y pdf.
        .buttons.mt-4.sizeErrorCloser
          b-button.ml-2(@click="toogleInvalidModal") Cerrar
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import _get from "lodash/get";
import Attach from "mdi-vue/Attachment.vue";
import Send from "mdi-vue/Send.vue";
import SendOutline from "mdi-vue/SendOutline";
import MonitorShare from "mdi-vue/MonitorShare.vue";
import ListStatus from "mdi-vue/ListStatus.vue";
import ModalListStatus from "./components/ModalListStatus";
import Modal from "./components/Modal";
import Camera from "mdi-vue/Camera.vue";
import ArrowDown from "mdi-vue/ChevronDown.vue";
import moment from "moment";
// import translate from "translate";
import EvalMobile from "@/utils/evalmobile";
import kuid from "kuid";
import ProgressBar from "vue-simple-progress";
import interact from "interactjs";

import VideoCall from "./components/VideoCall";
import Mobile from "../../utils/evalmobile";

// assets
import LogoColmedica from "@/assets/colmedica_logo.png";
import Mic from "@/assets/newDesign/VolumeHigh.svg";
import MicOff from "@/assets/newDesign/VolumeOff.svg";
import VideoCam from "@/assets/newDesign/CameraHigh.svg";
import VideoCamOff from "@/assets/newDesign/CameraOff.svg";
import VolumeHigh from "@/assets/VolumeHigh.png";
import VolumeHighOff from "@/assets/VolumeHighOff.png";
import PhoneOff from "@/assets/newDesign/PhoneOff.svg";

import LS from "@/helpers/localStorage";
var AWS = require("aws-sdk");
var albumBucketName = process.env.VUE_APP_BUCKET;
var bucketRegion = "us-east-1";
var IdentityPoolId = "us-east-1:26afdf2d-930c-41e5-81a6-7a703020ee16";
// let uploadedfile = [];

export default {
  name: "Meeting",

  props: ["meetType", "meetId", "username", "isComponent"],

  components: {
    VideoCall,
    Attach,
    Send,
    SendOutline,
    VolumeHigh,
    Mic,
    VideoCam,
    MonitorShare,
    ListStatus,
    ArrowDown,
    ModalListStatus,
    Camera,
    ProgressBar,
    Modal
  },

  data: () => ({
    showBtnScroll: false,
    showVisibleTape: false,
    observerLattestMessage: null,
    timeoutIO: null,
    observerVisibleTape: null,
    observerLattestMessageBody: null,
    loadingFile: false,
    showEndMeeting: false,
    fileNameLoading: "",
    fileExtension: "",
    typing: false,
    debugMode: false,
    timerDebugMode: false,
    debugCounter: 0,
    isiOS: Mobile.iOS(),
    message: "",
    timerWatcher: null,
    allowChat: true,
    agentcharged: false,
    deviceSelector: null,
    LogoColmedica,
    // icons
    PhoneOff: PhoneOff,
    Mic: Mic,
    MicOff: MicOff,
    VideoCam: VideoCam,
    VideoCamOff: VideoCamOff,
    VolumeHigh: VolumeHigh,
    VolumeHighOff: VolumeHighOff,
    validMimeTypes: [
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/octet-stream",
      "image/",
      // "video/",
      // "audio/",
      "application/pdf"
    ],
    translateFormat: null,
    showSendIcon: false,
    iFinishMeet: false,
    thereWasConnection: false,

    tempFile: null,

    // progress bar
    valueProgress: 0
  }),

  async created() {
    this.startup();

    setTimeout(() => {
      let trackingData = {
        uuid: window.person.id,
        accion: this.meetType + "-created-instanciar-videollamada",
        debmedia_turn_code: this.turn?.code,
        url_origen: window.location.href,
        origen: _get(this.this_user_data, "fu") || "ad",
        mensajes: JSON.stringify({
          meetingTitle: this.meetingTitle,
          "params.meetId": this.$route.params.meetId,
          meetId: this.meetId
        })
      };

      this.sendTracking(trackingData);
    }, 1000);

    // Check permissions
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      });
      console.log(stream);
    } catch (error) {
      alert("Debes dar permisos para usar la cámara");
    }
  },

  mounted() {
    const position = { x: 0, y: 0 };
    const boxAttendee = interact(".boxAttendee");

    boxAttendee.draggable({
      listeners: {
        move(event) {
          position.x += event.dx;
          position.y += event.dy;

          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
        }
      }
    });

    setTimeout(() => {
      AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IdentityPoolId
        })
      });

      let trackingData = {
        uuid: window.person.id,
        accion: this.meetType + "-mounted-instanciar-videollamada",
        debmedia_turn_code: this.turn?.code,
        url_origen: window.location.href,
        origen: _get(this.this_user_data, "fu") || "ad",
        mensajes: JSON.stringify({
          meetingTitle: this.meetingTitle,
          "params.meetId": this.$route.params.meetId,
          meetId: this.meetId
        })
      };

      this.sendTracking(trackingData);
    }, 1000);
  },

  destroyed() {
    if (this.observerLattestMessage) {
      this.observerLattestMessage.disconnect();
    }
    if (this.observerLattestMessageBody) {
      this.observerLattestMessage.disconnect();
    }
    if (this.timeoutIO) {
      clearTimeout(this.timeoutIO);
    }
    setTimeout(() => {
      let trackingData = {
        uuid: window.person.id,
        accion: this.meetType + "-destroyed-instanciar-videollamada",
        debmedia_turn_code: this.turn?.code,
        url_origen: window.location.href,
        origen: _get(this.this_user_data, "fu") || "ad",
        mensajes: JSON.stringify({
          meetingTitle: this.meetingTitle,
          "params.meetId": this.$route.params.meetId,
          meetId: this.meetId
        })
      };

      this.sendTracking(trackingData);
    }, 1000);
  },

  watch: {
    turnStatus(val) {
      if (val && val == "FINALIZED") this.end();
    },
    attendees(val) {
      if (val.length >= 2) {
        this.allowChat = true;
        this.agentController(true);

        // tracking
        let font = _get(this.c_data, "fu", "");
        font = !font || font == "" ? "ad" : font;
        let trackingData = {
          uuid: window.person.id,
          accion: this.meetType + "-conexion-videollamada",
          debmedia_turn_code: this.turn?.code,
          url_origen: window.location.href,
          origen: font,
          mensajes: ""
        };

        this.sendTracking(trackingData);
        this.thereWasConnection = true;
        this.$emit("attendees", false);

        // Check audio and video
        setTimeout(() => {
          console.log("ACTIVANDO VIDEO Y AUDIO AUTO...");
          console.log(this.localVideo);
          if (!this.localVideo) {
            this.startCameraHandler();
            this.toogleAudioEnabled();
          }
        }, 10000);
      } else {
        if (!this.thereWasConnection) this.$emit("attendees", true);
      }
    },

    messages(val) {
      let msg = val[this.messages.length - 1];
      if (!msg.isSelf && msg.text == "33Silver49Finalizado") {
        this.end();
      }

      this.timeoutIO = setTimeout(() => {
        const $messages = [
          ...document.querySelectorAll("#full_messages .messageCard")
        ];
        const $latestMessage =
          $messages.length !== 0
            ? $messages[$messages.length - 1]
            : $messages[0];

        if (msg.isSelf) {
          this.showBtnScroll = false;
          this.showVisibleTape = false;
          this.scrollHandler();
        } else {
          console.warn($latestMessage.querySelector(".pharragraph_message"));
          this.handlerIntersectionAgent(
            $latestMessage.querySelector(".pharragraph_message")
          );
        }
        this.handlerIntersectionMessage($latestMessage);
        clearTimeout(this.timeoutIO);
      }, 100);
    },

    loadingFile(val) {
      if (val) {
        this.$nextTick(() => {
          this.modeScroll(this.messages.length);
        });
      }
    },

    m_allow_video(val) {
      if (val) {
        this.$bvModal.show("Allowed-video");
      } else {
        this.$bvModal.hide("Allowed-video");
      }
    },

    // if the videid chine is not the same
    meetData(meet) {
      if (this.meetType === "agent") {
        if (this.meetId && meet.id) {
          if (this.meetId != meet.id) {
            location.reload();
          }
        }
      }
    }

    // meeting(val){
    //   console.log('meeting')
    //   console.log(val)
    //   if (!val) this.end();
    // }
  },

  computed: {
    ...mapState({
      version: state => state.version,
      meeting: state => state.meeting.meeting,
      retrieving: state => state.meeting.connecting,
      audioId: state => state.meeting.audioId,
      videosIds: state => state.meeting.videosIds,
      attendees: state => state.meeting.attendees,
      videoInputDevices: state => state.meeting.videoInputDevices,
      audioOutputDevices: state => state.meeting.audioOutputDevices,
      audioInputDevices: state => state.meeting.audioInputDevices,
      // devices
      speakerDevice: state => state.meeting.speakerDevice,
      videoDevice: state => state.meeting.videoDevice,
      audioDevice: state => state.meeting.audioDevice,
      // toggles
      audioEnabled: state => state.meeting.audioEnabled,
      camEnabled: state => state.meeting.camEnabled,
      speakerEnabled: state => state.meeting.speakerEnabled,
      videoStopSuggestion: state => state.meeting.videoStopSuggestion,
      isAgent: state => state.meeting.isAgent,

      messages: state => state.meeting.messages,
      sharingEnabled: state => state.meeting.sharingEnabled,
      c_data: state => state.client_data,

      waiting_time: state => state.max_waiting_time,
      turn: state => state.turn,
      m_allow_video: state => state.meeting.m_allow_video,
      env: state => state.env,

      isModeTutorial: state => state.olderAdult.isModeTutorial,

      actualTurn() {
        return _get(this.turn, "jsonDetails.actualTurn", "canceled");
      },

      meetData() {
        return _get(this.turn, "meet");
      },

      clientStorage() {
        try {
          let l_c = LS.getItem("dclient_data");
          return l_c.na + " " + l_c.ap;
        } catch (error) {
          console.log(error);
          return "";
        }
      }
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    helperAgent() {
      return this.turn.module?.worker_name || "";
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO_OA || this.LogoColmedica;
    },

    agentData() {
      const agent = this.turn.worker?.actualUser.uUser;
      return agent || null;
    },

    clientData() {
      // const isSelf = this.meeting.Attendee.AttendeeId ===
      //   this.agent;
      return this.turn?.worker?.actualTurn; // si viene de silver es agente, de lo contrario client
    },

    nameDataShowable() {
      return this.isAgent
        ? this.clientData
          ? `Cliente: ${this.clientData.getFullName}`
          : "No se encontró el cliente"
        : this.agentData
        ? `Asesor: ${this.agentData.firstName} ${this.agentData.lastName}`
        : "No se encontró el asesor";
    },

    debugButton() {
      return (
        this.env.VUE_APP_DEBUG_BUTTON === "true" ||
        this.meetType === "agent" ||
        this.debugMode
      );
    },

    meetingTitle() {
      return _get(this.meeting, "Meeting.Meeting.ExternalMeetingId");
    },

    turnStatus() {
      return _get(this.turn, "status");
    },

    meetingUrl() {
      return `${location.origin}/#/meeting/${this.meetingTitle}`;
    },

    assignerURL() {
      return `${location.origin}/#/turn_assigner/${this.meetingTitle}`;
    },

    selectedDeviceAllowed() {
      if (this.deviceSelector == "VideoInput") return this.videoInputDevices;
      if (this.deviceSelector == "AudioInput") return this.audioInputDevices;
      if (this.deviceSelector == "AudioOutput") return this.audioOutputDevices;
      return null;
    },

    deviceCurrent() {
      if (this.deviceSelector == "VideoInput") return this.videoDevice;
      if (this.deviceSelector == "AudioInput") return this.audioDevice;
      if (this.deviceSelector == "AudioOutput") return this.speakerDevice;
      return null;
    },

    toogleDevice() {
      if (this.deviceSelector == "VideoInput") return this.camEnabled;
      if (this.deviceSelector == "AudioInput") return this.audioEnabled;
      if (this.deviceSelector == "AudioOutput") return this.speakerEnabled;
      return false;
    },

    timeDay() {
      const dateMoment = moment().format("dddd, MMMM");
      const day = dateMoment.split(",")[0];
      const month = dateMoment.split(",")[1];
      const dayTranslation = this.evaluationDays(day);
      const monthTranslation = this.evaluationMonths(month);
      return `${dayTranslation}, ${moment().format(
        "D"
      )} de ${monthTranslation.toLowerCase()} de ${moment().format("YYYY")}`;
    },

    UsableHeight() {
      return window.innerHeight + "px";
    },

    isIOS() {
      return EvalMobile.iOS();
    },

    isSharedVideo() {
      return !!this.videosIds.find(vid => vid.isContent);
    },

    localVideo() {
      let result = this.videosIds.filter(vid => vid.localTile);
      return _get(result, "[0].active", false);
    },

    assignTurn() {
      return _get(this.turn, "jsonDetails.turn");
    }
  },

  methods: {
    ...mapMutations({
      toogleCamModal: "meeting/toogleCamModal"
    }),
    ...mapActions({
      setMeetType: "meeting/setMeetType",
      startCamera: "meeting/startCamera",
      setAtendeeName: "meeting/setAtendeeName",
      create: "meeting/startMeet",
      endMeet: "meeting/endMeet",
      changeVideoDevice: "meeting/changeVideoDevice",
      sendMessage: "meeting/sendMessage",
      changeSpeakerDevice: "meeting/changeSpeakerDevice",
      changeAudioDevice: "meeting/changeAudioDevice",
      // toogles
      toogleSharingEnabled: "meeting/toogleSharingEnabled",
      toogleCamEnabled: "meeting/toogleCamEnabled",
      toogleSpeakerEnabled: "meeting/toogleSpeakerEnabled",
      toogleAudioEnabled: "meeting/toogleAudioEnabled",
      // sendFile: "meeting/sendFile",
      putLog: "log/put",
      getTurnInfo: "getTurnInfo",
      getTurnCoorelation: "getTurnCoorelation",
      sendTracking: "virtualrow/handleTracking"
    }),

    scrollHandler() {
      const timer = setTimeout(() => {
        const $fullMessages = document.getElementById("full_messages");
        $fullMessages.scrollTop = $fullMessages.scrollHeight;
        clearTimeout(timer);
      }, 200);
    },

    debugModeCounter() {
      ++this.debugCounter;
      if (this.timerDebugMode) clearTimeout(this.timerDebugMode);
      if (this.debugCounter >= 5) {
        this.debugMode = !this.debugMode;
        this.debugCounter = 0;
      }
      this.timerDebugMode = setTimeout(() => {
        this.debugCounter = 0;
      }, 500);
    },

    showMsgBoxTwo(status) {
      this.$bvModal.msgBoxOk(
        status
          ? "Se ha restablecido la conexión de video."
          : "Se desconecta el video por problemas de red.",
        {
          title: "Evento de video",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true
        }
      );
    },

    async startup() {
      this.setMeetType(true); // this.meetType === "agent" // TODO: Marcelo y Karen piden esto.

      // if the component is call from turn
      if (this.isComponent) {
        try {
          this.setAtendeeName(
            this.$route.params.username || this.username || "Sin nombre"
          );

          let meetId = this.$route.params.meetId || this.meetId;
          // if (!meetId) {
          //   // this.turn = this.$route.params.turnId;
          //   // this.message = 'Obteniendo correlación...';
          //   const coo = await this.getTurnCoorelation(
          //     LS.getItem("coor_turnId")
          //   );
          //   this.getTurnInfo(coo.turn_code);

          //   meetId = coo.meet_id;
          // }

          await this.create({
            meetId: meetId,
            username: this.$route.params.username || this.username,
            turnCode: this.turn.code
          });

          this.putLog({
            name: "Meeting. Se ha creado la videollamada."
          });

          // this.agentController(); // TODO: enable it in demo to clients
        } catch (error) {
          this.putLog({
            name:
              "Meeting. Error restrictivo. No fue posible establecer la videollamada.",
            checked: false,
            message: error
          });
          this.$rollbar.warning("Stop meeting because a error", { error });
          this.$emit("endMeet");
        }
      } else {
        try {
          this.setAtendeeName(
            this.$route.params.username || this.username || "Sin nombre"
          );

          let meetId = this.$route.params.meetId || this.meetId;
          // if (this.meetType === "agent") {
          //   if (!meetId) {
          //     // this.turn = this.$route.params.turnId;
          //     // this.message = 'Obteniendo correlación...';
          //     const coo = await this.getTurnCoorelation(
          //       LS.getItem("coor_turnId")
          //     );
          //     this.getTurnInfo(coo.turn_code);

          //     meetId = coo.meet_id;
          //   }
          // }

          await this.create({
            meetId: meetId,
            username: this.$route.params.username || this.username,
            turnCode: this.turn.code
          });

          this.putLog({
            name: "Meeting. Se ha creado la videollamada."
          });
          // this.agentController(); // TODO: enable it in demo to clients
        } catch (error) {
          this.putLog({
            name:
              "Meeting. Error restrictivo. No fue posible establecer la videollamada.",
            checked: false,
            message: error
          });
          this.$rollbar.warning("Stop meeting because a error", { error });
          this.$emit("endMeet");
          this.endMeet();
        }
      }
    },

    startCameraHandler() {
      this.startCamera();
      setTimeout(() => {
        this.toogleCamModal();
      }, 1000);
    },

    async enableCamera() {
      await this.startCamera();
    },

    messageHandler() {
      if (!this.message) return;
      this.sendMessage({
        message: this.message + " name:" + this.clientStorage
      });
      this.message = "";
      this.showSendIcon = false;
    },

    async end(click = false) {
      // tracking
      if (click) {
        let font = _get(this.c_data, "fu", "");
        font = !font || font == "" ? "ad" : font;
        let trackingData = {
          uuid: window.person.id,
          accion: this.meetType + "-finish-videollamada",
          debmedia_turn_code: this.turn?.code,
          url_origen: window.location.href,
          origen: font,
          mensajes: ""
        };
        this.sendTracking(trackingData);
      }

      setTimeout(async () => {
        this.sendMessage({ message: "33Silver49Finalizado" });
        await this.endMeet();
        this.iFinishMeet = true;
        this.$emit("endMeet");
      }, 1000);
    },

    timeFormat(time) {
      return moment(time).format("hh:mm a");
    },

    agentController(canceled = false) {
      if (canceled == true) {
        clearTimeout(this.timerWatcher);
        this.timerWatcher = null;
        return;
      }
      this.timerWatcher = setTimeout(() => {
        this.$bvModal.show("Agent-not-connect");
      }, this.waiting_time * 1000);
    },

    cleanIM() {
      if (this.observerLattestMessage) {
        this.observerLattestMessage.disconnect();
      }
    },

    cleanIA() {
      if (this.observerLattestMessageBody) {
        this.observerLattestMessageBody.disconnect();
      }
    },

    cleanIATape() {
      if (this.observerVisibleTape) {
        this.observerVisibleTape.disconnect();
      }
    },

    handlerIntersectionAgent($latestMessage) {
      this.cleanIA();

      this.observerLattestMessageBody = new IntersectionObserver(
        this.onIntersectionAgent,
        {
          threshold: 0
        }
      );

      this.observerLattestMessageBody.observe($latestMessage);
    },

    handlerIntersectionMessage($latestMessage) {
      this.cleanIM();

      this.observerLattestMessage = new IntersectionObserver(
        this.onIntersectionMessage,
        {
          threshold: 0
        }
      );

      this.observerLattestMessage.observe(
        $latestMessage.querySelector(".msg_timestamp")
      );
    },

    onIntersectionMessage(entry) {
      for (const item of entry) {
        if (item.isIntersecting) {
          this.showVisibleTape = false;
          this.showBtnScroll = false;
        } else {
          this.showVisibleTape = true;
        }
      }
    },

    onIntersectionAgent(entry) {
      for (const item of entry) {
        if (item.isIntersecting) {
          this.showBtnScroll = false;
          this.cleanIA();
          this.scrollHandler();
        } else {
          this.showBtnScroll = true;
          this.cleanIA();
        }
      }
    },

    fileHandlerImage() {
      this.scrollHandler();
      this.fileHandler(this.$refs.image);
    },

    fileHandlerGeneral() {
      this.scrollHandler();
      this.fileHandler(this.$refs.file);
    },

    toogleInvalidModal() {
      this.$bvModal.hide("invalid-mime");
      this.$bvModal.hide("invalid-size");
    },

    async fileHandler(reference) {
      const files = reference.files;
      if (!files[0]) return;
      const infoFile = {
        name: files[0].name,
        ext: files[0].name.split(".")[files[0].name.split(".").length - 1],
        mime: files[0].type,
        size: files[0].size
      };
      const isValidMime = this.validMimeTypes.reduce(
        (prev, curr) => infoFile.mime.indexOf(curr) === 0 || prev,
        false
      );
      const isValidSize = infoFile.size < 10000000;
      if (!isValidMime) {
        this.$bvModal.show("invalid-mime");
        return;
      }
      if (!isValidSize) {
        this.$bvModal.show("invalid-size");
        return;
      }
      this.loadingFile = true;
      this.fileNameLoading = infoFile.name.split(".")[0];
      this.fileExtension = this.getFileExtension(infoFile.name);

      try {
        const { signed } = await this.fileHandlerSender(files[0]);
        const template = `
            <div class="position-relative DKLocoarsaTUUIO123456">
              <div style="display: flex; justify-content: space-between;">
                <p style="margin: 0;" class="fileDkSend">Archivo enviado correctamente</p>
                <img src="./../checkmark.png" style="max-width: 19px; width: 100%; height: 19px;" />
              </div>
              <a class="folderIcon-DK" download="${
                files[0].name
              }" target="_blank" style="display: flex;">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="#55bce2" d="M20,6A2,2 0 0,1 22,8V18A2,2 0 0,1 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10L12,6H20M19.25,13H16V9H14V13H10.75L15,17.25" />
              </svg>
              <p style="margin: 0; color: #55bce2; text-decoration: underline; text-truncate; text-overflow: ellipsis; overflow: hidden; max-width: 75%; white-space: nowrap !important;" class="fileChatOK text-truncate">${
                files[0].name.split(".")[0]
              }</p>
              <span style="color: #55bce2; text-decoration: underline;">.${this.getFileExtension(
                files[0]?.name
              )}</span>
            </a>
          </div>
        `;
        const timer = setTimeout(() => {
          this.sendMessage({ message: template });
          this.sendMessage({ message: "hidden-attach:" + signed });
          this.loadingFile = false;
          this.fileNameLoading = "";
          this.fileExtension = "";
          clearTimeout(timer);
        }, 200);
      } catch (error) {
        this.loadingFile = false;
        this.sendMessage({
          message: `
            <div class="position-relative">
              <i style="font-size: 0.7rem; color: brown;">Tu archivo ${files[0].name} no pudo ser cargado. Inténtalo de nuevo.</i>
            </div>
          `,
          local: true
        });
        window.RB.warning("Invalid file to charge", { infoFile });

        // tracking
        let font = _get(this.c_data, "fu", "");
        font = !font || font == "" ? "ad" : font;
        let trackingData = {
          uuid: window.person.id,
          accion: "error-send-file-chat",
          debmedia_turn_code: this.turn?.code,
          url_origen: window.location.href,
          origen: font,
          mensajes: error + " " + JSON.stringify(this.tempFile)
        };

        this.sendTracking(trackingData);
      }
      reference.value = "";
    },

    async simulateAttach() {
      this.simulateClick(this.$refs.file);
    },

    async simulateImage() {
      this.simulateClick(this.$refs.image);
    },

    async fileHandlerSender(file) {
      const result = {
        turn_code: this.turn?.code,
        call_id: this.turn?.code, // FIXME: Code ... check current meeting id
        name: file.name,
        type: file.type,
        b64: file,
        size: file.size
      };

      this.tempFile = result;

      // let key = await this.sendFile(result);
      this.preloadFile = true;
      let files = await this.uploadFilesToS3(result);
      this.preloadFile = false;
      return files;
    },
    readed(file) {
      return new Promise((res, rej) => {
        var reader = new FileReader();
        reader.onload = () => res(reader.result);
        reader.onerror = err => rej(err);
        reader.readAsDataURL(file);
      });
    },

    simulateClick(elem) {
      // Create our event (with options)
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      });
      // If cancelled, don't dispatch our event
      elem.dispatchEvent(evt);
    },

    selectedDeviceInput(devices) {
      this.deviceSelector = devices;
      this.$bvModal.show("Input-selector");
    },

    changeDeviceSelected(device) {
      if (this.deviceSelector == "VideoInput") this.changeVideoDevice(device);
      if (this.deviceSelector == "AudioInput") this.changeAudioDevice(device);
      if (this.deviceSelector == "AudioOutput")
        this.changeSpeakerDevice(device);
      this.closeModalDevices();
    },

    disableDevice() {
      if (this.deviceSelector == "VideoInput") this.toogleCamEnabled();
      if (this.deviceSelector == "AudioInput") this.toogleAudioEnabled();
      if (this.deviceSelector == "AudioOutput") this.toogleSpeakerEnabled();
      this.closeModalDevices();
    },

    startCamFromBasis() {
      if (!this.videoDevice) this.toogleCamModal();
      else this.toogleCamEnabled();
    },

    closeModalDevices() {
      // close modal and leave selector at null
      setTimeout(() => {
        this.$bvModal.hide("Input-selector");
      }, 600);
    },

    handleChangeMessage() {
      this.showSendIcon = true;
    },

    getFileExtension(filename) {
      return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    },

    modeScroll(messageNumber) {
      var myMsg = document.getElementById(`message_${messageNumber - 1}`);
      if (myMsg) {
        var topPos = myMsg.offsetTop;
        document.getElementById("full_messages").scrollTop = topPos;
      }
    },

    evaluationMonths(month) {
      let monthTranslation;
      if (month.includes("January")) monthTranslation = "Enero";
      else if (month.includes("February")) monthTranslation = "Febrero";
      else if (month.includes("March")) monthTranslation = "Marzo";
      else if (month.includes("April")) monthTranslation = "Abril";
      else if (month.includes("May")) monthTranslation = "Mayo";
      else if (month.includes("June")) monthTranslation = "Junio";
      else if (month.includes("July")) monthTranslation = "Julio";
      else if (month.includes("August")) monthTranslation = "Agosto";
      else if (month.includes("September")) monthTranslation = "Septiembre";
      else if (month.includes("October")) monthTranslation = "Octubre";
      else if (month.includes("November")) monthTranslation = "Noviembre";
      else if (month.includes("December")) monthTranslation = "Diciembre";
      else monthTranslation = month;
      return monthTranslation;
    },

    evaluationDays(day) {
      let dayTranslation;
      switch (day) {
        case "Monday":
          dayTranslation = "Lunes";
          break;
        case "Tuesday":
          dayTranslation = "Martes";
          break;
        case "Wednesday":
          dayTranslation = "Miércoles";
          break;
        case "Thursday":
          dayTranslation = "Jueves";
          break;
        case "Friday":
          dayTranslation = "Viernes";
          break;
        case "Saturday":
          dayTranslation = "Sábado";
          break;
        default:
          dayTranslation = "Domingo";
          break;
      }
      return dayTranslation;
    },

    signObject(key) {
      return new Promise(resolve => {
        try {
          // let key_ = '/' + key.url.split('prod/')[1].split('.')[0];
          let s3 = new AWS.S3();

          const signedUrlExpireSeconds = 60 * 30; // your expiry time in seconds.
          let res = s3.getSignedUrl("getObject", {
            Bucket: albumBucketName,
            Key: key,
            Expires: signedUrlExpireSeconds
          });

          resolve(res);
        } catch (error) {
          console.log(error);
          resolve(key);
        }
      });
    },

    uploadFilesToS3(element) {
      return new Promise(resolve => {
        let nameSplitted = element.name.split(".");
        let mom = moment().utc();
        let nameOfUuidfile = `${kuid()}.${
          nameSplitted[nameSplitted.length - 1]
        }`;
        let hour = mom.format("HH");
        var timedate = mom.format("DDMMYYYY");
        var file = element.b64;
        this.valueProgress = 0;

        // var xhr = new XMLHttpRequest();
        const destiny = `${timedate}/${hour}/${element.call_id}/chat_files/${nameOfUuidfile}`;

        var opts = { queueSize: 1, partSize: 1024 * 1024 * 5 };
        var params = { Key: destiny, ContentType: file.type, Body: file };

        let s3 = new AWS.S3({
          params: {
            Bucket: albumBucketName,
            Key: destiny,
            Body: file
          }
        });
        var upload = s3
          .upload(params, opts)
          .on("httpUploadProgress", evt => {
            // console.log("Uploaded :: " + parseInt((evt.loaded * 100) / evt.total)+'%');
            this.valueProgress = parseInt((evt.loaded * 100) / evt.total);
          })
          .promise();

        /* eslint-disable */
        upload.then(async (data, err) => {
          
          if(!err){
            console.log("************* Successfully uploaded video. *****************");
            console.log(data)

            let s_key = await this.signObject(destiny);
            
            let obj_t = {
              signed: s_key,
              // url: this.env.VUE_APP_FILE_SERVER + '/' + destiny
              url: destiny
            };
            // uploadedfile.push(obj_t);
            resolve(obj_t);
          } else {
            console.log("************* ERROR! *****************");
            console.log(err)
          }

        });
        
      } )

    },

    download(id){
      // if is attachmen, must arrive another message with url
      if(this.messages[id].text.includes('DKLocoarsaTUUIO123456')){
        try {
          let attach_url = this.messages[id+1].text.replace('hidden-attach:', '');
          let nameFile =  this.messages[id].text.match(/download=.([^"]+)/)[1];

          // return window.open(attach_url, "_blank");

          // setTimeout(()=>{

          //   // let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
          //   let iframe
          //   let time = 0;
          //   let id = 'export_operations_g' + Math.random();  
            
          //   if (iframe == null) {

          //     iframe = document.createElement('iframe')
          //     iframe.id = id
          //     iframe.style.display = 'none'
          //     document.body.appendChild(iframe)
          //   }

          //   iframe.src = attach_url;
          //   let timer = setInterval(() => {
          //     time++
          //     iframe = document.getElementById(id)
          //     try{
          //       let iframeDoc = iframe.contentDocument || iframe.contentWindow.document
          //       if (iframeDoc.readyState == 'complete' || iframeDoc.readyState == 'interactive') {
          //         console.log('La descarga se ha realizado correctamente.')
          //         clearInterval(timer)
          //         return
          //       }
          //     }catch(err){
          //         if(time == 5){
          //           console.log('El archivo no existe. ')
          //           console.log(err)
          //           clearInterval(timer) 
          //           return
          //         }
          //     }
          //   } , 1000) 
          // }, 2000)
          
          let a= document.createElement('a');
          a.target= '_blank';
          a.download= nameFile;
          a.href= attach_url;
          a.click();

        } catch (error) {
          console.log(error)
        }
      }
    },

    nameParticipant(text){
      console.log(text);
      let tsp = text.split('name:');
      return tsp.length > 1 ? tsp[1] : '';
    }

  }
};

</script>

<style lang="scss" scoped>
.videoStopSuggestionMessage {
  position: absolute;
  bottom: -5px;
  text-align: center;
  width: 100%;
  font-size: smaller;
  z-index: 3;
}

.boxAttendee {
  width: 100%;
  max-width: 184px;
  padding: 0.5rem;

  position: fixed;
  top: 96px;
  right: 32px;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 8;

  background: #F4F4F4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .ImportantAsignment,
  .font-larger {
    color: #009fe3;
  }
  .font-larger {
    margin: auto;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
  .AssignmentTextAttendee,
  .ImportantAsignment {
    font-size: 16px;
  }
  .ImportantAsignment {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .ActualAttendee {
    font-size: 14px;
  }
}

.header {
  height: 6%;
}

.videozone {
  height: 440px;
  max-width: 712px;
  margin-right: 16px;
  width: 100%;
}

.chatzone {
  height: 100%;
  min-height: calc(100vh - 45%);
  width: 100%;
  max-width: 470px;
}

.containerMsg {
  padding: 8px;
  padding-top: 0px;
  height: 100%;
  position: relative;
}

.messages {
  overflow: auto;
  max-height: 440.39px;
  scroll-behavior: smooth;
  padding: 1rem;
}

.messagesZone {
  flex: 1;
  height: 100%;
  position: relative;
  // max-height: 440.39px;
  padding-right: 3px !important;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
}

.containerInputMessages {
  padding-right: 0.8rem;
}

.fullChat {
  border-radius: 10px;
  height: 100%;
  display: flex;
  max-width: 950px;
  margin: auto;
  background-color: #e9ebf2;
  flex-direction: column-reverse;
}

.dateTittle {
  text-align: center;
  margin: 0px 5px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  color: #949da5;
  border-bottom: 1px solid #6c757d;
  padding: 0 5px;
}

.fileChatOK {
  width: 50%;
  min-width: 80px;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dateTittle span:nth-child(1) {
  font-size: 0.9rem;
}

/* .dateTittle span:nth-child(1) {
  color: #54678f;
  font-weight: 600;
  width: 40%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: start;
} */

.img_cont_msg {
  height: 40px;
  width: 40px;
}

.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}

.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 5px 0px 5px 5px;
  position: relative;
  max-width: 100%;
}

.msg_text {
  padding: 5px 10px;
  border-radius: 5px;
  overflow: auto;
  font-size: 1.05rem;
}

.msg_unique_text {
  padding: 5px 10px;
  border-radius: 0;
  overflow: auto;
  font-size: 0.95rem;
}

.rotatedk {
  transform: rotate(240deg);
}

.folderIcon-DK {
  /* // width: 100px; */
  height: 80px;
  padding-top: 50px;
  /* // border: solid 1px #000; */
  position: relative;
  display: flex;
  text-align: center;
}

.text-truncate {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.uniqueMessage {
  margin: 0;
  margin-bottom: 0 !important;
  opacity: 0;
  animation: opacityToggle 1s forwards ease-in-out;
}

.loading_msg-dk {
  border-radius: 5px 5px 0 0;
}

.marginNegative {
  margin-top: -5px;
  opacity: 0;
  animation: opacityToggle 1s forwards ease-in-out;
}

.marginNegative .msg_cotainer {
  width: 100%;
}

.image_msg-dk {
  background: url(./../loading-buffering.gif);
  background-size: cover;
  height: 19px;
  max-width: 19px;
  background-repeat: no-repeat;
  animation: changeImage 1s forwards ease-in-out;
}

.uniqueMessage .msg_cotainer {
  border-radius: 0;
  width: 100%;
}

.msg_owner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  color: #6d7074;
}

.msg_owner_client {
  text-align: right;
  justify-self: flex-end;
  justify-content: flex-end;
}

.msg_owner_agent {
  text-align: left;
}

.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 5px; /* 25px; */
  background-color: #c3d9c8; /* #78e08f; */
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
}

.msg_time {
  left: 0;
  bottom: -15px;
  color: darkgray;
  font-size: 10px;
  text-align: right;
}

.msg_time_send {
  text-align: right;
  right: 0;
  bottom: -15px;
  color: darkgray;
  font-size: 10px;
  margin: 0px 10px;
  display: block;
}

.attach_btn {
  /* border-radius: 5px 0 0 5px !important; 15px 0 0 15px !important; */
  border: 0 !important;
  cursor: pointer;
  height: auto;
  display: block;

  background-color: rgba(0, 0, 0, 0) !important;
  color: #6c757d !important;
}

.send_btn {
  width: 30px;
  height: 30px;
  margin-right: 5px;

  border-radius: 10px !important; /* 0 15px 15px 0 !important; */
  border: 0 !important;
  width: 48px;
  height: 48px;
  border-radius: 10px;

  background-color: transparent !important;
  color: #f4f4f4 !important;
}

.send_btn img {
  width: 15px;
  transform: rotate(45deg);
}

.type_msg {
  // background-color: rgba(0, 0, 0, 0.1) !important;
  background: none;
  border: 0 !important;
  color: #6c757d !important;
  height: 40px !important;
  overflow-y: auto;
}

.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.bottomzone {
  max-width: 285px;
  margin: auto;
  margin-top: 15px;
}

.chatWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  // background-color: #e9ebf2;
  border-radius: 20px;
  max-width: 470px;
  min-width: 327px;
}

.d-lfex-dk-dk {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .chatWrapper {
    min-height: 400px;
  }
}

.size-buttons {
  width: 58px;
  height: 58px;
  margin: 0px auto;
  position: relative;
  display: block;
  padding: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.size-buttonMic {
  margin: 0px auto;
  position: relative;
  display: block;
}

/* tootle */
.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-toogle {
  height: 40px;
}

.fontSizeButton {
  font-size: 14px;
}

.version-position {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 8px;
  color: #6c757d;
}

.justify-content-start {
  max-width: 80%;
}

.justify-content-end {
  display: flex;
  align-self: flex-end;
  -webkit-align-self: flex-end;
}

.green-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2cb9b0;
  margin-right: 5px;
}

.red-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #b92c2c;
  margin-right: 5px;
}

.lds-ellipsis {
  display: flex;
  position: relative;
  width: 50px;
  align-items: center;
  height: 30px;
}

.lds-ellipsis div {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.isHiden img {
  background-color: #fb0101;
}

.lds-ellipsis div:nth-child(1) {
  left: 5px;
  animation: lds-ellipsis1 0.8s infinite ease-in-out;
}

.lds-ellipsis div:nth-child(2) {
  left: 5px;
  animation: lds-ellipsis2 0.8s infinite ease-in-out alternate;
}

.lds-ellipsis div:nth-child(3) {
  left: 17px;
  animation: lds-ellipsis2 0.8s infinite ease-in-out alternate;
}

.lds-ellipsis div:nth-child(4) {
  left: 33px;
  animation: lds-ellipsis3 0.8s infinite ease-in-out alternate;
}

.typing {
  border-radius: 10px;
  background-color: #2e303e;
  width: 42px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-self: flex-end;
  -webkit-align-self: flex-end;
  position: relative;
}

.chatContainerFlex {
  width: 100%;
  max-width: 470px;
}

.typing-2 {
  border-radius: 10px;
  background-color: #54678f;
  width: 42px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  position: relative;
}

.triangulo {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #212624;
  border-top: 19px solid #0000;
  border-bottom: 0px solid #0000;
  /* top: 0; */
  bottom: 0px;
  right: -5px;
}

.disabled-triangle {
  border-left: 10px solid rgb(105 117 112);
  /* border-top: 19px solid rgb(33 38 36 / 60%); */
  border-bottom: 0px solid rgb(33 38 36 / 60%);
}

.input-group {
  flex: 1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px;
  align-items: center;
  margin-top: 21px !important;
}

.triangulo-chat {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #2e303e;
  border-top: 19px solid #0000;
  border-bottom: 0px solid #0000;
  /* top: 0; */
  bottom: 3px;
  right: -3px;
}

.dangerButton {
  background-color: #FB0101;
  border: none;
}

.disabled {
  opacity: 0.7;
}

.triangulo_noself {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 11px solid #54678f;
  border-top: 0px solid #0000;
  border-bottom: 15px solid #0000;
  left: -5px;
  top: 19px;
  transform: rotate(90deg);
}

.triangulo_noself-chat {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #54678f;
  border-top: 0px solid #0000;
  border-bottom: 14px solid #0000;
  left: -2px;
  bottom: 15px;
  transform: rotate(90deg);
}

.pharragraph_message {
  width: fit-content;
  max-width: 95%;
  /* min-width: 80px; */
  text-align: start;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  margin: 0;

  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #081e3d;
}

.tap {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  // background: black;
  right: 0;
  z-index: 2;
}

.text-label {
  color: #212624;
  margin-top: 10px;
}

.inputContainer {
  flex: 1;
}

.meetHelper {
  text-align: center;
  max-width: 940px;
  width: 100%;
  margin: auto;
  color: #6C6B74;
  margin-top: 5px;
}

.text-danger-dk {
  color: #FB0101;
}

.iconClose {
  position: absolute;
  left: 0;
  top: 32%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1080px) {
  .videozone {
    max-width: 50vw;
  }
}

@media screen and (max-width: 780px) {
  .videozone {
    max-width: 430px;
    padding: 0 !important;
    margin: 0;
  }

  .chatWrapper, .containerMsg {
    height: 400px;
    min-height: auto;
  }

  .ContainerFlexMedia {
    flex-wrap: wrap;
  }

  .chatContainerFlex {
    margin-top: 7rem;
  }

  .messagesZone {
    height: 350px;
    // width: 414px;
    border-radius: 10px;
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    // background: #f4f4f4;
  }

  .chatzone {
    min-height: fit-content;
    height: fit-content;
  }

  .chatzone {
    margin-top: 28px;
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacityToggle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes changeImage {
  0% {
    background: url(./../loading-buffering.gif);
  }
  100% {
    background: url(./../checkmark.png);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(15px, 0);
  }
}

.main_box {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  text-align: center;
  font-size: 1em;
  font-family: Helvetica-light;
}

@keyframes showOutArrowDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.showBtnScroll {
  position: absolute;
  right: 19px;
  bottom: 95px;
  width: 50px;
  height: 50px;
  box-shadow: none !important;
  border: none;
  background: none;
}

.showBtnScroll span {
  z-index: 1;
  color: #6c757d;
  position: relative;
}

span.bg-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  border-radius: 50%;
  background-color: white;
  opacity: 0.9;
  z-index: -1;
}

.redAlert {
  background: #F60303;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  right: -5px;
  top: -5px;
}

.arrowBtnDown {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
  opacity: 0;
  animation: 1s ease-in-out showOutArrowDown forwards;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
}

.msg_timestamp {
  visibility: hidden;
  height: 10px;
}

@keyframes showOutArrowDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
